/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const AddGain = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [gain1, setGain1] = React.useState("");
  const [gain2, setGain2] = React.useState("");
  const [gain3, setGain3] = React.useState("");
  const [gain4, setGain4] = React.useState("");
  const [gain5, setGain5] = React.useState("");
  const [gain6, setGain6] = React.useState("");
  const [gain7, setGain7] = React.useState("");
  const [gain8, setGain8] = React.useState("");

  React.useEffect(() => {
    if (state.gains && state.gains.length > 7) {
      setGain1(state.gains[0]?.name);
      setGain2(state.gains[1]?.name);
      setGain3(state.gains[2]?.name);
      setGain4(state.gains[3]?.name);
      setGain5(state.gains[4]?.name);
      setGain6(state.gains[5]?.name);
      setGain7(state.gains[6]?.name);
      setGain8(state.gains[7]?.name);
    }
  }, []);

  const save = () => {
    if (
      gain1 &&
      gain2 &&
      gain3 &&
      gain4 &&
      gain5 &&
      gain6 &&
      gain7 &&
      gain8 &&
      state?.id
    ) {
      const token = localStorage.getItem("access_token");
      axios
        .post(
          "/api/lessons/gain",
          {
            id: state?.id,
            gain: [
              { name: gain1 },
              { name: gain2 },
              { name: gain3 },
              { name: gain4 },
              { name: gain5 },
              { name: gain6 },
              { name: gain7 },
              { name: gain8 },
            ],
          },
          { headers: { Authorization: "Bearer: " + token } }
        )
        .then((res) => navigate("/teacher/lessons"));
    } else {
      alert("Kazanımlar boş bırakılamaz");
    }
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Kazanımları Düzenle</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={save} size="sm">
                      Kaydet
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 1
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 1"
                            type="text"
                            value={gain1}
                            onChange={(e) => setGain1(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 2
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 2"
                            type="text"
                            value={gain2}
                            onChange={(e) => setGain2(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 3
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 3"
                            type="text"
                            value={gain3}
                            onChange={(e) => setGain3(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 4
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 4"
                            type="text"
                            value={gain4}
                            onChange={(e) => setGain4(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 5
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 5"
                            type="text"
                            value={gain5}
                            onChange={(e) => setGain5(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 6
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 6"
                            type="text"
                            value={gain6}
                            onChange={(e) => setGain6(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 7
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 7"
                            type="text"
                            value={gain7}
                            onChange={(e) => setGain7(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kazanım 8
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Kazanım 8"
                            type="text"
                            value={gain8}
                            onChange={(e) => setGain8(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddGain;
