/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Classes from "views/examples/Classes.js";
import Icons from "views/examples/Icons.js";
import Students from "views/examples/Students";
import Lessons from "views/examples/Lessons";
import Teachers from "views/examples/Teachers";
import AddStudents from "views/examples/AddStudents";
import AddClasses from "views/examples/AddClasses";
import AddTeacher from "views/examples/AddTeacher";
import AddLessons from "views/examples/AddLessons";
import ClassesTeacher from "views/examples/ClassesTeacher";
import LessonsTeacher from "views/examples/LessonsTeacher";
import StudentsTeacher from "views/examples/StudentsTeacher";
import AddGain from "views/examples/AddGain";
import AddNote from "views/examples/AddNote";
import Report from "views/examples/Report";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    seen: false,
    seenTeacher: false,
  },
  {
    path: "/classes",
    name: "Sınıflar",
    icon: "ni ni-bullet-list-67",
    component: <Classes />,
    layout: "/admin",
    seen: true,
    seenTeacher: false,
  },
  {
    path: "/add-classes",
    name: "Sınıf Ekle",
    icon: "ni ni-bullet-list-67",
    component: <AddClasses />,
    layout: "/admin",
    seen: false,
    seenTeacher: false,
  },
  {
    path: "/students",
    name: "Öğrenciler",
    icon: "ni ni-single-02",
    component: <Students />,
    layout: "/admin",
    seen: true,
    seenTeacher: false,
  },
  {
    path: "/add-students",
    name: "Öğrenci Ekle",
    icon: "ni ni-single-02",
    component: <AddStudents />,
    layout: "/admin",
    seen: false,
    seenTeacher: false,
  },
  {
    path: "/lessons",
    name: "Dersler",
    icon: "ni ni-collection",
    component: <Lessons />,
    layout: "/admin",
    seen: true,
    seenTeacher: false,
  },
  {
    path: "/add-lessons",
    name: "Öğrenci Ekle",
    icon: "ni ni-single-02",
    component: <AddLessons />,
    layout: "/admin",
    seen: false,
    seenTeacher: false,
  },
  {
    path: "/teachers",
    name: "Eğitmenler",
    icon: "ni ni-bag-17",
    component: <Teachers />,
    layout: "/admin",
    seen: true,
    seenTeacher: false,
  },
  {
    path: "/add-teacher",
    name: "Öğrenci Ekle",
    icon: "ni ni-single-02",
    component: <AddTeacher />,
    layout: "/admin",
    seen: false,
    seenTeacher: false,
  },
  {
    path: "/reports",
    name: "Öğrencilerim",
    icon: "ni ni-single-02",
    component: <Report />,
    layout: "/admin",
    seen: false,
    seenTeacher: false,
  },

  {
    path: "/classes",
    name: "Sınıflarım",
    icon: "ni ni-bullet-list-67",
    component: <ClassesTeacher />,
    layout: "/teacher",
    seen: false,
    seenTeacher: true,
  },
  {
    path: "/addGain",
    name: "Kazanım Ekle",
    icon: "ni ni-bullet-list-67",
    component: <AddGain />,
    layout: "/teacher",
    seen: false,
    seenTeacher: false,
  },
  {
    path: "/lessons",
    name: "Derslerim",
    icon: "ni ni-collection",
    component: <LessonsTeacher />,
    layout: "/teacher",
    seen: false,
    seenTeacher: true,
  },
  {
    path: "/students",
    name: "Öğrencilerim",
    icon: "ni ni-single-02",
    component: <StudentsTeacher />,
    layout: "/teacher",
    seen: false,
    seenTeacher: true,
  },
  {
    path: "/addNote",
    name: "Öğrencilerim",
    icon: "ni ni-single-02",
    component: <AddNote />,
    layout: "/teacher",
    seen: false,
    seenTeacher: false,
  },
  /*
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
    seen: true,seenTeacher:false
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: <Icons />,
    layout: "/admin",
    seen: true,seenTeacher:false
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: <Maps />,
    layout: "/admin",
    seen: true,seenTeacher:false
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
    seen: true,seenTeacher:false
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: <Tables />,
    layout: "/admin",
    seen: true,seenTeacher:false
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    seen: true,seenTeacher:false
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
    seen: true,seenTeacher:false
  },*/
];
export default routes;
