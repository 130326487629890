/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const AddNote = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [gain1Name, setGain1Name] = React.useState("");
  const [gain2Name, setGain2Name] = React.useState("");
  const [gain3Name, setGain3Name] = React.useState("");
  const [gain4Name, setGain4Name] = React.useState("");
  const [gain5Name, setGain5Name] = React.useState("");
  const [gain6Name, setGain6Name] = React.useState("");
  const [gain7Name, setGain7Name] = React.useState("");
  const [gain8Name, setGain8Name] = React.useState("");

  const [gain1, setGain1] = React.useState(0);
  const [gain2, setGain2] = React.useState(0);
  const [gain3, setGain3] = React.useState(0);
  const [gain4, setGain4] = React.useState(0);
  const [gain5, setGain5] = React.useState(0);
  const [gain6, setGain6] = React.useState(0);
  const [gain7, setGain7] = React.useState(0);
  const [gain8, setGain8] = React.useState(0);

  const [ozduzenleme, setOzduzenleme] = React.useState(0);
  const [zaman, setZaman] = React.useState(0);
  const [coklugorev, setCoklugorev] = React.useState(0);
  const [katilim, setKatilim] = React.useState(0);
  const [motivasyon, setMotivasyon] = React.useState(0);
  const [ifade, setIfade] = React.useState(0);
  const [liderlik, setLiderlik] = React.useState(0);
  const [yaraticilik, setYaraticilik] = React.useState(0);
  const [sayginlik, setSayginlik] = React.useState(0);

  React.useEffect(() => {
    if (state.gains && state.gains.length > 7) {
      setGain1Name(state.gains[0]?.name);
      setGain2Name(state.gains[1]?.name);
      setGain3Name(state.gains[2]?.name);
      setGain4Name(state.gains[3]?.name);
      setGain5Name(state.gains[4]?.name);
      setGain6Name(state.gains[5]?.name);
      setGain7Name(state.gains[6]?.name);
      setGain8Name(state.gains[7]?.name);
    }
  }, []);

  const save = () => {
    if (
      gain1 &&
      gain2 &&
      gain3 &&
      gain4 &&
      gain5 &&
      gain6 &&
      gain7 &&
      gain8 &&
      gain1Name &&
      gain2Name &&
      gain3Name &&
      gain4Name &&
      gain5Name &&
      gain6Name &&
      gain7Name &&
      gain8Name &&
      ozduzenleme &&
      zaman &&
      coklugorev &&
      katilim &&
      motivasyon &&
      ifade &&
      liderlik &&
      yaraticilik &&
      sayginlik &&
      state?.id
    ) {
      const token = localStorage.getItem("access_token");
      axios
        .post(
          "/api/students/add_note",
          {
            id: state?.id,
            note: [
              { name: gain1Name, note: gain1 },
              { name: gain2Name, note: gain2 },
              { name: gain3Name, note: gain3 },
              { name: gain4Name, note: gain4 },
              { name: gain5Name, note: gain5 },
              { name: gain6Name, note: gain6 },
              { name: gain7Name, note: gain7 },
              { name: gain8Name, note: gain8 },
              { name: "Özdüzenleme", note: ozduzenleme },
              { name: "Zaman Farkındalığı", note: zaman },
              { name: "Çoklu Görev ve Dikkat	", note: coklugorev },
              { name: "Derse Katılım ve Etkinlik Arayışı	", note: katilim },
              { name: "Motivasyon", note: motivasyon },
              { name: "Kendini İfade Etme - Özgüven ", note: ifade },
              { name: "Liderlik", note: liderlik },
              { name: "Yaratıcılık", note: yaraticilik },
              { name: "Saygınlık", note: sayginlik },
            ],
          },
          { headers: { Authorization: "Bearer: " + token } }
        )
        .then((res) => {
          navigate("/teacher/students");
        });
    } else {
      alert("Notlar boş bırakılamaz");
    }
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`Kazanımları Düzenle (${state?.name})`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={save} size="sm">
                      Kaydet
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Genel Kazanımlar
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Özdüzenleme
                          </label>
                          <Input
                            type="number"
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Özdüzenleme"}
                            value={ozduzenleme}
                            onChange={(e) =>
                              setOzduzenleme(`${e.target.value}`)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Zaman Farkındalığı
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Zaman Farkındalığı"}
                            type="number"
                            value={zaman}
                            onChange={(e) => setZaman(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Çoklu Görev ve Dikkat
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Çoklu Görev ve Dikkat	"}
                            type="number"
                            value={coklugorev}
                            onChange={(e) => setCoklugorev(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Derse Katılım ve Etkinlik Arayışı
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Derse Katılım ve Etkinlik Arayışı"}
                            type="number"
                            value={katilim}
                            onChange={(e) => setKatilim(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Motivasyon
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Motivasyon"}
                            type="number"
                            value={motivasyon}
                            onChange={(e) => setMotivasyon(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kendini İfade Etme - Özgüven
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Kendini İfade Etme -  Özgüven"}
                            type="number"
                            value={ifade}
                            onChange={(e) => setIfade(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Liderlik
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Liderlik"}
                            type="number"
                            value={liderlik}
                            onChange={(e) => setLiderlik(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Yaratıcılık
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Yaratıcılık"}
                            type="number"
                            value={yaraticilik}
                            onChange={(e) =>
                              setYaraticilik(`${e.target.value}`)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Saygınlık
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Saygınlık"}
                            type="number"
                            value={sayginlik}
                            onChange={(e) => setSayginlik(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Atölye Kazanımları
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain1Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain1Name}
                            type="number"
                            value={gain1}
                            onChange={(e) => setGain1(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain2Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain2Name}
                            type="number"
                            value={gain2}
                            onChange={(e) => setGain2(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain3Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain3Name}
                            type="number"
                            value={gain3}
                            onChange={(e) => setGain3(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain4Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain4Name}
                            type="number"
                            value={gain4}
                            onChange={(e) => setGain4(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain5Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain5Name}
                            type="number"
                            value={gain5}
                            onChange={(e) => setGain5(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain6Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain6Name}
                            type="number"
                            value={gain6}
                            onChange={(e) => setGain6(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain7Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain7Name}
                            type="number"
                            value={gain7}
                            onChange={(e) => setGain7(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain8Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain8Name}
                            type="number"
                            value={gain8}
                            onChange={(e) => setGain8(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddNote;
